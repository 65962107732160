import React from "react"
import Seo from "../components/SEO"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import BannerModule from "../components/BannerModule/BannerModule"
import Faq from "../components/Faq/Faq"
import Features from "../components/Features/Features"
import RichText from "../components/RichText"
import Gallery from "@browniebroke/gatsby-image-gallery"

const ProductTemplateStyles = styled.div`
  .container {
    display: flex;
    flex-wrap: wrap;
    .column {
      flex: 0 0 100%;

      @media (min-width: 768px) {
        flex-basis: 50%;

        &:nth-child(1) {
          padding-right: 20px;
        }

        &:nth-child(2) {
          padding-left: 20px;
        }

        > * {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      > * {
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
`

const ProductGallery = styled.section`
  width: 100%;

  > div {
    display: flex;
    flex-wrap: wrap;

    > * {
      cursor: pointer;
    }
  }
`

const Producttemplate = (contentfulProject) => {
  const {
    headerImage,
    title,
    introduction,
    description,
    faqs,
    gallery,
  } = contentfulProject
  const productHeaderImage = getImage(headerImage)
  return (
    <>
      <Seo title={title} />
      <BannerModule title={title} subTitle={introduction} enquire={true}>
        <GatsbyImage
          className="banner__image"
          image={productHeaderImage}
          alt={title}
        />
      </BannerModule>
      <ProductTemplateStyles className="section">
        <div className="container container__tight">
          {description && (
            <div className="column">
              <RichText richText={description} />
            </div>
          )}
          {faqs && (
            <div className="column">
              {faqs.map((item, index) => {
                return (
                  <Faq
                    key={index}
                    title={item.question}
                    description={item.answer}
                  />
                )
              })}
            </div>
          )}
        </div>
      </ProductTemplateStyles>

      {gallery && (
        <ProductGallery className="section">
            <Gallery images={gallery} />
        </ProductGallery>
      )}
      <Features
        title="Featured Projects from SObespoke."
        introduction="We specialise in: bespoke colour schemes; curtains, blinds and shutters; procurement and styling of unique spaces; renovations or new builds."
      />
    </>
  )
}

export default Producttemplate
